// src/components/footer.js
import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import useLanguageNavigate from '../hooks/useLanguageNavigate';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        backgroundColor: '#f5f5f5',
        mt: 'auto',
        width: '100%',
        padding: '10px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Typography
          variant="body2"
          color="black"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleNavigate('/terms-of-use')}
        >
          {t('termsOfUse')}
        </Typography>
        <Typography
          variant="body2"
          color="black"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleNavigate('/privacy-policy')}
        >
          {t('privacyPolicy')}
        </Typography>
      </Box>
      <Divider
        sx={{
          width: '80%',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          mb: 3,
          mt: 2,
        }}
      />
      <Typography variant="body2" color="textSecondary">
        Copyright © 2024 Superior Apartments
      </Typography>
    </Box>
  );
};

export default Footer;
