import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import pl from './locales/pl.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            pl: { translation: pl },
            en: { translation: en },
        },
        fallbackLng: 'pl',
        supportedLngs: ['pl', 'en'],
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            lookupFromPathIndex: 0,
            caches: ['cookie'],
            checkWhitelist: true,
        },
        react: {
            useSuspense: true,
        }
    });

export default i18n;
