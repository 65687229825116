import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        const newLang = i18n.resolvedLanguage === 'pl' ? 'en' : 'pl';
        i18n.changeLanguage(newLang);
        navigate(`/${newLang}${window.location.pathname.slice(3)}`);
    };

    return (
        <div>
            <IconButton onClick={handleClick} color="inherit" sx={{ ml: 1 }}>
                <LanguageIcon />
            </IconButton>
        </div>
    );
};

export default LanguageSwitcher;
