import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useTranslation } from 'react-i18next';
import LanguageRoutes from './components/LanguageRoutes';
import useLanguageNavigate from './hooks/useLanguageNavigate';
import RedirectToLanguage from './components/RedirectToLanguage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const { t } = useTranslation();
  const navigate = useLanguageNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#ffcd4e',
      },
      secondary: {
        main: '#00d5ad',
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      h6: {
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
  });

  const actions = [
    { label: t('management'), onClick: () => navigate(`/management`) },
    { label: t('contact'), onClick: () => navigate(`/contact`) },
    { label: t('book'), onClick: () => navigate(`/book`) },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#FAF9FD',
        }}
      >
        <Navbar actions={actions} />
        <main>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<RedirectToLanguage />} />
            <Route path="/:lang/*" element={<LanguageRoutes />} />
          </Routes>
        </main>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}


export default App;
